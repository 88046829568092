import { Height } from "@material-ui/icons";

const stElemnt = {
  AF: {
    cell: {
      padding: "5px 0px",
      textAlign: "-webkit-center",
      margin: "0px",
      whiteSpace: "nowrap",
      width: "0px",
      // border:'solid',
      border: "solid 0.1px",
      fontFamily: "Amiri-Regular",
      // color:'red',
      // display:'none',
      // border:'solid 1px red',
    },
    header: {
      //  color:'red',
      // border:'solid 1px orange',
      padding: "15px 0px",
      textAlign: "-webkit-center",
      margin: "0px",
      whiteSpace: "nowrap",
      width: "0px",
      fontFamily: "Amiri-Regular",
      backgroundColor: "#E1E1E1",
      fontWeight: "800",

      // borderLeft:'solid'
      border: "solid 0.1px",
    },

    Reviws: {
      Typogmain: {
        backgroundColor: "#fff",
        width: "21cm",
        color: "black",
        padding: "0.6cm",
      },
      ReviwsJard: {
       
          backgroundColor: "#fff",
          width: "21cm",
          color: "black",
          paddingRight: "0.3cm",
          paddingLeft: "0.3cm",

        },
        ReviwsUnv: {
       
          backgroundColor: "#fff",
          width: "21cm",
          color: "black",

        },

      TypogmainLand: {
        backgroundColor: "#fff",
        width: "42cm",
        color: "black",
        padding: "0.6cm",
      },
      Typogsub: {
        border: "dotted 1px #393939",
        padding: "0.2cm 0.4cm",
        minHeight: "28.4cm",
      },
      pdf_page: {
        border: "dotted 1px #393939",
        // padding: "0.2cm 0.4cm",
        paddingRight:"0.2cm",
        paddingLeft:"0.2cm",

        minHeight:"29.7cm",
        maxHeight:"29.7cm",
        display:"flex",
        flexDirection:"column",
        boxSizing:"border-box",
       
      },
        pdf_inv: {
        border: "dotted 1px #393939",
        // padding: "0.2cm 0.4cm",
        minHeight:"29.7cm",
        maxHeight:"29.7cm",
        display:"flex",
        flexDirection:"column",
        boxSizing:"border-box",
       
      },
      TypogsubLand: {
        border: "dotted 1px #393939",
        padding: "0.6cm",
        minHeight: "59.4cm",
      },

      continr: {
        backgroundImage: "linear-gradient(to right, #4e9e8480 , #2688ba80)",
        justifyContent: "center",
        display: "inline-grid",
      },
      /* Customize */
      EDay: {},
    },
  },

  Casher: {
    cell: {
      padding: "5px 0px",
      textAlign: "-webkit-center",
      margin: "0px",
      whiteSpace: "nowrap",
      width: "0px",
      border: "solid 1px",
      // color:'red',
      // display:'none',
      // border:'solid 1px red'
    },
    header: {
      //  color:'red',
      // border:'solid 1px orange',
      padding: "15px 0px",
      textAlign: "-webkit-center",
      margin: "0px",
      whiteSpace: "nowrap",
      width: "0px",
      // backgroundColor:'red',
      border: "solid 1px",
    },
    Reviws: {
      continr: {
        backgroundImage: "linear-gradient(to right, #4e9e8480 , #2688ba80)",
        // padding:8,
        justifyItems: "center",
        display: "grid",
        width: "100%",
      },
      Typogmain: {
        backgroundColor: "#fff",
        padding: "0.1cm",
      },
    },
  },

  EvDay: {
    cell: {
      padding: "5px 0px",
      textAlign: "-webkit-center",
      margin: "0px",
      whiteSpace: "nowrap",
      width: "0px",
      border: "dotted 0.5px",
      // color:'red',
      // display:'none',
      // border:'solid 1px red'
    },
    header: {
      //  color:'red',
      // border:'solid 1px orange',
      padding: "5px 0px",
      textAlign: "-webkit-center",
      margin: "0px",
      // whiteSpace: 'nowrap',
      width: "0px",
      // backgroundColor:'red',
      border: "dotted 0.5px",
    },
  },
};

const cellS = {
  padding: "5px 0px",
  textAlign: "-webkit-center",
  margin: "0px",
  whiteSpace: "nowrap",
  width: "0px",
  color: "green",
  // border:'solid 1px red'
};

const headerS = {
  color: "green",
  // border:'solid 1px orange',
  padding: "0px",
  textAlign: "-webkit-center",
  margin: "0px",
  whiteSpace: "nowrap",
  width: "0px",
  // backgroundColor:'red'
};

export { stElemnt };
