import React, { useState, useEffect, Fragment } from "react";
import { uri } from "../../../../help/Api";
import * as _ from "lodash";
import { sto } from "../../../../Context/InshData";
import HeaderJard from "./HeaderJard";
import BodyJard from "./GardTable";
import FooterA from "../../../Accounts/Balance/A4/FooterA";
import { Grid, Typography } from "@material-ui/core";
import { stElemnt } from "../../../../St/Reviews/cStyle";
import { Rept } from "../../../../Context/InshData";
import useChunkMyData from "../../../../components/Hooks/useChunkMyData";
import useFetchURL from "../../../../components/Hooks/useFetchURL";

function OnePage(props) {
  const { found, chunk, isFirst, isLast,num } = props;
  return (
    <Grid item container style={{ ...stElemnt.AF.Reviws.pdf_page }}>

   
    <Fragment  >
    {isFirst&& <HeaderJard found={found} />}
      <BodyJard
        data={chunk ?? []}
        isFullPage={!isFirst ? true : false}
        isLast={isLast}
        
      />

      <FooterA found={found} 
       isLast={isLast}
       num={num}/>
    </Fragment>
    </Grid>
  );
}

function ReviewJardContainer(props) {
  const { chunks, chunksCount } = useChunkMyData(Rept.data, 22, 35);
  const url = uri + `Foundation?branch=${sto.branchi}`;
  const { data: found, fetching } = useFetchURL({
    ...{ url, dependencyArray: [] },
  });
 
  if (fetching || !found || !chunks) return <div></div>;

  return (
    <Typography component="div" style={stElemnt.AF.Reviws.ReviwsJard}>
      {/* <Typography component="div" style={{ ...stElemnt.AF.Reviws.pdf_page }}> */}
      <Typography component="div" >
        {chunks.map((page, i) => {
          return (
            <OnePage
              {...{
                found,
                chunk: page,
                isFirst: i == 0,
                isLast: i == chunksCount - 1,
                num:i+1,
              }}
            />
          );
        })}
      </Typography>
    </Typography>
  );
}
export default ReviewJardContainer;
