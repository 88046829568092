import React from "react";
import { Switch, Redirect, useRouteMatch, Route } from "react-router-dom";

import ReviewAF from "./Reviews/Invs/AFReadyDisigen/RevAF"; /* AFReadyDisigen */
import Foundation from "./comps/bascDatas/Foundation";

import ReviewMQ from "./Reviews/ReviewMQ";
import ReviewMultiSarf from "./Reviews/MultiSarf/Review";

import ReporteveryDay from "./comps/Sales/BackEnd/Tab1Rout/EveryDay/ReporteveryDay";
import ReportEqfalEveryDay from "./comps/Sales/BackEnd/Tab1Rout/EqfalEveryDay/ReportEqfalEveryDay";
import ReportActivity from "./comps/Sales/BackEnd/Tab1Rout/Activity/ReportActivity";
import ReportActivityP from "./comps/Purchases/BackEnd/Tab1Rout/Activity/ReportActivity";
import ReportSalTafsele from "./comps/Sales/BackEnd/Tab1Rout/Tafsele/ReportSalTafsele";
// import ReportSalTafsele from "./comps/Sales/BackEnd/Tab1Rout/ReportSalTafsele";

// Safes
import ReportSafesJard from "./comps/Safes/BackEnd/Tab1Rout/ReportSafesJard";

import HesabCustAmeel from "./comps/Customers/BackEnd/Tab1Rout/HesabCustAmeel";
import HesabCustMored from "./comps/Customers/BackEnd/Tab1Rout/HesabCustMored";
import "./i18n";

// Settings
import SettingsPrinter from "./comps/Settings/SettingsPrinter";
import PrintCasher from "./comps/Settings/PrintCasher";
import Settings from "./comps/Settings/Settings";
import ReData from "./comps/Settings/ReData";

// import Units from './comps/bascDatas/Tab1/Units';
import SimpleData from "./comps/bascDatas/SimpleData";
import NormalData from "./comps/bascDatas/NormalData";
import Reports from "./comps/Taqrer/Reports";

import RestrictionInsert from "./comps/Accounts/BackEnd/Restrictions/RestrictionInsert";

import MultiQbd from "./comps/Accounts/BackEnd/Tabs/MultiQbd";
import MultiSarf from "./comps/Accounts/BackEnd/Tabs/MultiSarf";
import Sandskharji from "./comps/Accounts/BackEnd/Tabs/Sandskharji";
import FirstTermInvoice from "./comps/Invs/BackEnd/first-term-invoice";

import DeptCards from "./Fronts/DeptsMenues/DeptCards";
// Safes

import { createTheme } from "@material-ui/core/styles";

import { MuiThemeProvider } from "@material-ui/core/styles";
import ReviewAmeelMoard from "./Reviews/Customers/Balance/ReviewAmeelMoard";
import RevAccounting from "./Reviews/Accounts/Balance/RevAccounting";
import RevSandC from "./Reviews/Accounts/Kharji/Casher/RevSandC";
import ReviewEqfalEveryDay from "./Reviews/Sales/Eqfal/ReviewEqfalEveryDay";
import ReviewActivity from "./Reviews/Sales/Activity/ReviewActivity";
import ReviewSalTafsele from "./Reviews/Sales/Tafsele/ReviewSalTafsele";
import ReviewEveryDay from "./Reviews/Sales/EvryDay/ReviewEveryDay";
import ReviewJard from "./Reviews/Safes/Jard/ReviewJard";
import ReportTax from "./comps/Accounts/BackEnd/ReportTax/ReportTax";
import RestrictionPrint from "./Reviews/Accounts/restPrint/RestrictionPrint";
import ReportTaxPrinter from "./Reviews/Accounts/rptTaxPrint/ReportTaxPrinter";
import GlobalContext from "./Context/GlobalContext";
import useFetchURL from "./components/Hooks/useFetchURL";
import { sto } from "./Context/InshData";
import RestInvoice from "./comps/Invs/BackEnd/rest-invoice";
import { useEffect } from "react";
import ReMakeStoreCon from "./comps/Safes/BackEnd/Store/ReMakeStoreCon";
import InternalSand from "./comps/Accounts/BackEnd/Tabs/InternalSand";
import Groups from "./comps/bascDatas/Tab1/groups/groups";
import GroupsCon from "./comps/bascDatas/Tab1/groups/groupsCon";
import TaswayaPrint from "./Reviews/Safes/taswya/TaswyaPrint";
import SaleReportTax from "./comps/Accounts/BackEnd/SaleReportTax/saleReportTax";
import RevCasherBuilder from "./Reviews/Invs/run-time-design/RevCasher";
import TaxSalePrint from "./Reviews/Sales/TaxSalePrint/TaxSalePrint";
import SafeGrdUnits from "./comps/Safes/BackEnd/SafeGrdUnits/SafeGrdUnits";
import CurrencyDetailsRPT from "./comps/Safes/BackEnd/CurrencyDetailsRPT/CurrencyDetailsRPT";
import TrunItemsAvg from "./comps/Safes/BackEnd/TrunItemsAvg/TrunItemsAvg";
import { AccountsIndexCont } from "./comps/Accounts/BackEnd/AccountsIndexes/AccountIndexCont";
import SaleAnalysisCon from "./comps/Sales/BackEnd/Tab1Rout/SaleAnalysis/saleAnalysisCon";
import CurrencySalesProfitCon from "./comps/Sales/BackEnd/Tab1Rout/CurrencySalesProfit/CurrencySalesProfitCon";
import EmpSalesCon, {
  EmpPurchCon,
} from "./comps/Sales/BackEnd/Tab1Rout/EmpSales/EmpSalesCon";
import CashairNoTax from "./Reviews/Invs/CashierNoTax/CashairNoTax";
import StoreTransferCon from "./comps/Safes/BackEnd/StoreTransfer/StoreTransferCon";

import SaleAnalysisPrint from "./Reviews/Sales/sale-analysis/SaleAnalysisPrint";
import MainRBalanceCon from "./comps/Accounts/BackEnd/MainRBalance/MainRBalanceCon";
import DashboardCon from "./comps/Dashboard/DashboardCon";
import OmlatCon from "./comps/bascDatas/omlat/omlatCon";
import ChangePassword from "./comps/users/change-password";
import EReceiptVoucherCon from "./comps/Accounts/BackEnd/receipt-voucher/external-receipt-voucher/ERVCon";
import EExchangeVoucherCon from "./comps/Accounts/BackEnd/receipt-voucher/external-exchange-voucher/EEVCon";
import UserCon from "./comps/Customers/BackEnd/users/userCon";
import AccountingRPTCon from "./comps/Accounts/BackEnd/AccountingRPT/accountingRPTCon";
import SafesCon from "./comps/bascDatas/safes/safesCon";
import StocksCon from "./comps/bascDatas/stocks/stocksCon";
import DefaultSettingsCon from "./comps/Settings/defaultSettings/default-settingsCon";
import IExchangeVoucherCon from "./comps/Accounts/BackEnd/receipt-voucher/internal-exchange-voucher/IEVCon";
import IReceiptVoucherCon from "./comps/Accounts/BackEnd/receipt-voucher/internal-receipt-voucher/IRVCon";
import SalesManInvoice from "./comps/Invs/BackEnd/salesman-invoice";
import InvoiceCon from "./comps/Invs/BackEnd/invoiceCon";
import CustomersCon from "./comps/Customers/customers/customersCon";
import AccountCustRPTCon from "./comps/Customers/accountCustRPT/accountingRPTCon";
import ReceiveSTCon from "./comps/Safes/BackEnd/StoreTransfer/receiveStoreTransfer/ReceiveSTCon";
import BalanceCustRPTCon from "./comps/Customers/balanceCustRPT/balanceCustRPTCon";
import ItemsCon from "./comps/bascDatas/Tab1/items/ItemsCon";
import PaymentTransactionRPTCon from "./comps/Customers/paymentTransactionRPT/paymentTransactionRPTCon";
import BillTemplate from "./Reviews/Invs/bill-template/bill-template";
import CustomerBalance from "./Reviews/Customers/customerBalance/customerBalance";
import TrialBalance from "./Reviews/Accounts/trial-balance/TrialBalance";
import MReceiptVoucherCon, {
  MExchangVoucherCon,
} from "./comps/Accounts/BackEnd/receipt-voucher/multi-receipt-voucher/MVCon";
import MultiVoucherView from "./Reviews/MultiVoucherView";
import ProfitMarginCon from "./comps/Sales/BackEnd/Tab1Rout/profit-margin/ProfitMarginCon";
import ResturantCasher from "./Reviews/Invs/casher-resturant/RevCasher";
import GoldTemplate from "./Reviews/Invs/gold-template/gold-template";
import SafeGrdCon from "./comps/Safes/BackEnd/safe-grd/SafeGrdCon";
import BackupAllDataBase from "./comps/Settings/backup-all-db";
import BackupDataBase from "./comps/Settings/backup-db";
import StoreExchangeCon from "./comps/Safes/BackEnd/StoreExchange/StoreExchangeCon";
import StoreSupplyCon from "./comps/Safes/BackEnd/StoreSupply/StoreSupplyCon";
import UserPermissionsCon from "./comps/Settings/user-permissions/user-permissions-con";
import VoucherTemplate from "./Reviews/Accounts/voucher-template/voucher-template";
import UsersPermissionsCont from "./comps/users/UserPermissions/UserPermissionsCont";
import ORestrictionsCon from "./comps/Accounts/BackEnd/opening-restrictions/opening-restrictionsCon";
import RevCasher from "./Reviews/Invs/CasherReadyDisigen/RevCasher";
import Bigen from "./Start/Bigen";
import PrivateRoute from "./utils/PrivateRoute";
import { NotFound } from "./POS/views";
import BalanceSheetRPT from "./Reviews/Accounts/balance-sheet/BalanceSheet";
import CostCentersCon from "./comps/bascDatas/Tab1/CostCenters/CostCentersCon";
import CostCenterRptCon from "./comps/Accounts/BackEnd/CostCenterRpt/CostCenterRptCon";
import PrintExample from "./Reviews/Invs/bill-template/printExample";
import BigenDemo from "./Start/Bigen-demo";
import EmpoyeesCon from "./comps/users/employees/EmpoyeesCon";
import SalesPersonCon from "./comps/bascDatas/SalesPerson/SalesPersonCon";
import SaleManCommCon from "./comps/Sales/BackEnd/Tab1Rout/SaleManComm/SaleManCommCon";
import SafeTransferPrint from "./Reviews/Safes/transefer/TransferPrint";
import BillTemplateTwo from "./Reviews/Invs/bill-template02/bill-template-two";
import PurchAnalysisCon from "./comps/Sales/BackEnd/Tab1Rout/SaleAnalysis/purchAnalysisCon";
import ElectronicScaleCon from "./comps/Settings/electronic-scale/electronic-scaleCon";
import ZatcaSettingsCon from "./comps/Settings/zatca-settings/zatcaCon";
import InvoiceSettings from "./comps/Settings/invoiceSettings";
import BillTemplateTwoBuilder from "./Reviews/Invs/bill-template02-builder/bill-template-two";
import InvoiceSettingsApp from "./comps/Settings/invoice-settings/invoice-settings";
import DRestrictionsCon from "./comps/Accounts/BackEnd/Restrictions/daily-restrictionsCon";
import StockMoveRPTCon from "./comps/Sales/BackEnd/Tab1Rout/stock-move/stockMoveCon";
import SalesManPrint from "./Reviews/Sales/sales-man/SalesManPrint";
import BanksCon from "./comps/bascDatas/banks/banksCon";
import LoginTest from "./login";
import SubscriptionRenewCon from "./comps/Settings/subscription-renew/subscription-renewCon";
import RevCostCenter from "./Reviews/Accounts/CostReportPrint/RevCostCenter";
import DefaultInv from "./Reviews/Invs/AFReadyDisigen/DefaultInv";
import InvCompany from "./Reviews/Invs/invCompany/invCompanytow";
import ReviewJardReport from "./Reviews/Safes/Jard/RevJard";
import RevMaintainceInv from "./Reviews/Invs/maintaince/maintainceinv";

const MainRoutes = () => {
  if (!sto)
    return (
      <Switch>
        <Route exact path="/" component={Bigen} />
        {/* for dimo */}
        {/* <Route exact path="/" component={BigenDemo} /> */}
      </Switch>
    );

  return (
    <Switch>
      <Route exact path="/" component={Bigen} />
      {/* for dimo */}
      {/* <Route exact path="/" component={BigenDemo} /> */}
      <Route path="/groups" component={GroupsCon} />
      {/* <Route exact path='/' component={Custos} /> */}
      <Route exact path="/restInsert" component={DRestrictionsCon} />
      <Route path="/print" component={PrintExample} />
      <Route path="/ReportAcc" component={AccountingRPTCon} />
      <Route path="/Foundation" component={Foundation} />
      {/* <Route path='/SalesInvO' component={SalesInvO} /> */}
      <Route path="/item" component={ItemsCon} />
      {/* <Route path='/unit' component={Units} /> */}
      <Route path="/reportTax" component={ReportTax} />
      <Route path="/SimData" component={SimpleData} />
      <Route path="/NormData" component={NormalData} />
      <Route path="/Omlat" component={OmlatCon} />
      <Route path="/change-password" component={ChangePassword} />
      {/* <Route path='/Invoces' component={Invoces} /> */}
      <Route path="/Invoces" component={InvoiceCon} />

      <Route path="/first-term-invoice" component={FirstTermInvoice} />

      <Route path="/-invoice" component={SalesManInvoice} />
      <Route path="/mQbd" component={MultiQbd} />
      <Route path="/mSarf" component={MultiSarf} />
      <Route path="/Sands" component={Sandskharji} />
      <Route path="/internal-sand" component={InternalSand} />
      <Route path="/Customers" component={CustomersCon} />
      <Route path="/ReporteveryDay" component={ReporteveryDay} />
      <Route path="/ReportEqfalEveryDay" component={ReportEqfalEveryDay} />
      <Route path="/Activity" component={ReportActivity} />
      <Route path="/Activityp" component={ReportActivityP} />
      <Route path="/ReportSalTafsele" component={ReportSalTafsele} />
      <Route path="/ReportSaleAnalysis" component={SaleAnalysisCon} />
      <Route path="/ReportPurchAnalysis" component={PurchAnalysisCon} />
      <Route path="/ReportProfitMargin" component={ProfitMarginCon} />
      <Route path="/ReportEmpSales" component={EmpSalesCon} />
      <Route path="/ReportEmpPurches" component={EmpPurchCon} />
      <Route path="/CurrencySalesProfit" component={CurrencySalesProfitCon} />
      <Route path="/ReportSafesJard1" component={ReportSafesJard} />
      <Route path="/ReportSafesJard" component={SafeGrdCon} />
      <Route path="/Reports" component={Reports} />

      <Route path="/HesabCustM" component={AccountCustRPTCon} />
      <Route path="/HesabCustA" component={AccountCustRPTCon} />
      <Route path="/BalanceCustRPT" component={BalanceCustRPTCon} />
      <Route path="/HesabCustAmeel" component={HesabCustAmeel} />
      <Route path="/HesabCustMored" component={HesabCustMored} />
      <Route path="/SettingsP" component={SettingsPrinter} />
      <Route path="/casher" component={PrintCasher} />
      <Route path="/Sett" component={Settings} />
      <Route path="/Refresh" component={ReData} />
      <Route path="/backup-all" component={BackupAllDataBase} />
      <Route path="/backup" component={BackupDataBase} />
      {/* <Route path="/A" component={ReviewAF} /> */}


<Route path="/A" component={DefaultInv} />
      <Route path="/RevCompany" component={InvCompany} />
      <Route path="/RevInv" component={RevMaintainceInv} />


      

      
      <Route path="/bill2" component={BillTemplate} />
      <Route path="/bill4" component={BillTemplateTwo} />
      <Route path="/voucher2" component={VoucherTemplate} />
      <Route path="/Invoces/bill2" component={BillTemplate} />
      <Route path="/gold-bill" component={GoldTemplate} />
      <Route path="/C" component={RevCasher} />
      <Route path="/CashairNoTax" component={CashairNoTax} />
      <Route path="/RestCasher" component={ResturantCasher} />
      {/* <Route path='/ReviewMSQ' component={ReviewMSQ} /> */}
      <Route path="/ReviewMQ" component={ReviewMQ} />
      <Route path="/MulVoucher" component={MultiVoucherView} />
      <Route path="/ReviewMS" component={ReviewMultiSarf} />
      {/* <Route path='/ReviewAaM' component={ReviewAmeelMoard}  /> */}
      <Route path="/ReviewAaM" component={ReviewAmeelMoard} />
      <Route path="/ReviewEqfal" component={ReviewEqfalEveryDay} />
      <Route path="/ReviewActivity" component={ReviewActivity} />
      <Route path="/ReviewTafsel" component={ReviewSalTafsele} />
      <Route path="/ReviewEDay" component={ReviewEveryDay} />
      {/* <Route path="/ReviewJard" component={ReviewJard} /> */}
      <Route path="/ReviewJard" component={ReviewJardReport} />
      <Route path="/ReviewAccount" component={RevAccounting} />
      <Route path="/ReviewCostCenter" component={RevCostCenter} />
      <Route path="/mult-receipt" component={MReceiptVoucherCon} />
      <Route path="/mult-exchange" component={MExchangVoucherCon} />
      <Route path="/CustBalance" component={CustomerBalance} />
      <Route path="/RP" component={RestrictionPrint} />

      <Route path="/SC" component={RevSandC} />
      <Route path="/reStore" component={ReMakeStoreCon} />
      <Route path="/store-exch" component={StoreExchangeCon} />
      <Route path="/store-supp" component={StoreSupplyCon} />
      <Route path="/RTP" component={ReportTaxPrinter} />
      <Route path="/TASP" component={TaswayaPrint} />
      <Route path="/sale-report-tax" component={SaleReportTax} />
      <Route path="/depts" component={DeptCards} />
      <Route path="/TSP" component={TaxSalePrint} />
      <Route path="/SafeGrdUnits" component={SafeGrdUnits} />
      <Route path="/curr-details-rpt" component={CurrencyDetailsRPT} />
      <Route path="/trun-items-avg" component={TrunItemsAvg} />
      <Route path="/example" component={RevCasherBuilder} />
      <Route path="/example01" component={BillTemplateTwoBuilder} />
      <Route path="/chart" component={DashboardCon} />
      <Route path="/accounts-index" component={AccountsIndexCont} />
      <Route path="/StoreTransfer" component={StoreTransferCon} />
      <Route path="/ReceiveST" component={ReceiveSTCon} />
      <Route path="/AnalysisPrint" component={SaleAnalysisPrint} />
      <Route path="/MainRBalance" component={MainRBalanceCon} />
      {/* <Route path="/MainRBalanceRpint" component={TrialBalance} /> */}
      <Route path="/MainRBalanceRpint" component={BalanceSheetRPT} />
      <Route path="/ext-receipt" component={EReceiptVoucherCon} />
      <Route path="/ext-exchange" component={EExchangeVoucherCon} />

      <Route path="/rpted" component={AccountingRPTCon} />
      <Route path="/safes" component={SafesCon} />
      <Route path="/stocks" component={StocksCon} />
      <Route path="/default_setting" component={DefaultSettingsCon} />
      <Route path="/int-exchange" component={IExchangeVoucherCon} />
      <Route path="/int-receipt" component={IReceiptVoucherCon} />
      <Route path="/customersCon" component={CustomersCon} />
      <Route path="/pay-trans" component={PaymentTransactionRPTCon} />
      <Route path="/permissions" component={UserPermissionsCon} />
      <Route path="/login" component={LoginTest} />
      <Route path="/zatca-settings" component={ZatcaSettingsCon} />


      <Route path="/opening-rest" component={ORestrictionsCon} />
      <Route path="/transfer-print" component={SafeTransferPrint} />
      <Route path="/rest-invoice" component={RestInvoice} />
      <PrivateRoute
        path="/users-curd"
        component={UserCon}
        isAllowed={sto.User_typei == 1}
      />
      <PrivateRoute
        path="/employees"
        component={EmpoyeesCon}
        isAllowed={sto.User_typei == 1}
      />
      <PrivateRoute
        path="/user-perms"
        component={UsersPermissionsCont}
        isAllowed={sto.User_typei == 1}
      />

      <Route component={NotFound} exact path="/not-found" />
      <Route component={CostCentersCon} exact path="/costCenters" />
      <Route component={CostCenterRptCon} exact path="/costRpt" />
      <Route component={SalesPersonCon} exact path="/salesPerson" />
      <Route component={SaleManCommCon} exact path="/salesPersonComm" />
      <Route component={ElectronicScaleCon} exact path="/electronic-scale" />
      <Route component={InvoiceSettings} exact path="/invoice-settings" />
      <Route component={StockMoveRPTCon} exact path="/stock-move" />
      <Route component={SalesManPrint} exact path="/SalesManPrint" />
      <Route component={BanksCon} exact path="/banks" />
      <Route component={SubscriptionRenewCon} exact path="/renew" />
      <Route
        component={InvoiceSettingsApp}
        exact
        path="/invoice-settings-app"
      />
      <Redirect to="/not-fount" />
      {/* <CashOutStoreore/> */}
    </Switch>
  );
};

export default MainRoutes;
