import { makeStyles } from "@material-ui/core/styles";
import { insh } from "../../Context/InshSt";

//====================================================== B Inv
//================= B AF
const useInvAF = makeStyles((theme) => ({
  /* S Head */
  Hedroot: {
    border: "solid 1px",
    borderRadius: "8px",
    // width: '100%',
    width: "20.4cm",
    marginLeft: "0.3cm",
  },
  HedtotSub: {
    // padding:2,
    // marginLeft:8,
    // marginRight:8,
    // marginTop:-2,
    border: "solid 0.1px",
    borderRadius: "6px",
    padding: 2,
    margin: 10,
    marginBottom: 0,
  },
  HedtxtLeft: {
    bottom: "1px",
    display: "flex",
    border: "solid 1px",
    borderRadius: 5,
  },
  Hedlogo: {
    height: 100,
    width: 110,
  },
  /* E Head */

  Bdyroot: {
    flexGrow: 1,
  },
  BdyundrHeaderTF: {
    marginTop: "1rem",
    // zIndex: 1,
  },
  BdyundrHeader: {
    marginTop: "0.6rem",
    // border: 'solid 1px',
    marginBottom: "2rem",
    // zIndex: 1,
  },
  BdytitleHed: {
    // color:'#8D201C',
    backgroundColor: "#f5ebdb ",
    textAlign: "center",
    border: "solid 1px",
    borderRadius: "6px",
    padding: 6,
  },
  BdytitleHedMusa: {
    // color:'#8D201C',
    backgroundColor:"#f5ebdb ",
    textAlign: "center",
    border: "solid 1px",
    borderRadius: "5px",
    padding: 3,
  },
  BdytitleHedQ2: {
    // color:'#8D201C',
    backgroundColor: "#f5ebdb ",
    textAlign: "center",
    border: "solid 1px",
    borderRadius: "6px",
    color: "#7f0101",
    fontWeight: "bold",
    paddingBottom:6,
    paddingTop:6,
    fontSize: "0.8rem",
    fontFamily: "Amiri-Regular",
    boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%)",
  },
  BdytitleHed1: {
    // color:'#8D201C',
    backgroundColor: "#ebF5db ",
    textAlign: "center",
    border: "solid 1px",
    borderRadius: "6px",
    padding: 2,
  },
  BdytotTitle: {
    paddingLeft: 12,
    paddingRight: 12,
    border: "solid 0.1px",
    borderRadius: "6px",
    padding: 2,
    margin: 10,
    marginBottom: 0,
    fontFamily: "Amiri-Regular",
  },
  BdytotSub: {
    padding: 2,
    marginLeft: 8,
    marginRight: 8,
    marginTop: -2,
    fontFamily: "Amiri-Regular",
  },

  BdytotLf: {
    paddingLeft: 10,
    borderLeft: "solid 0.1px",
    borderBottom: "solid 0.1px",
  },
  BdytotRit: {
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    textAlign: "center",
  },
  BdytotCntr: {
    paddingRight: 10,
    borderLeft: "solid 0.1px",
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    textAlign: "right",
  },
  BdytotCntMmm: {
    paddingRight: 10,
    borderLeft: "solid 0.1px",

    textAlign: "right",
  },
  footerA: {
  
  paddingLeft:20,
  paddingRight:20,

  },
  footerB: {
    paddingLeft:20,
  paddingRight:20,
  paddingBottom:15,
  paddingTop:10,
  },

  BdyborBtL: {
    borderBottom: "solid 0.1px",
    borderLeft: "solid 0.1px",
  },
  BdyborBtR: {
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
  },
  BdyborBtRTOP1: {
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    borderTop: "solid 0.1px",
    marginTop:12,  
    // borderLeft: "solid 0.1px",    
  },
  BdyborBtRTOPTotal: {
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    borderTop: "solid 0.1px",
    borderLeft: "solid 0.1px", 
    marginTop:10, 
    paddingRight:10,

    // borderLeft: "solid 0.1px",    
  },
  BdyborBtRTOPTotal2: {
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    borderLeft: "solid 0.1px", 
    paddingRight:10,  
 
  },
  BdyborBtRTOP2: {
    borderBottom: "solid 0.1px",
    // borderRight: "solid 0.1px",
    borderTop: "solid 0.1px",
    borderLeft: "solid 0.1px", 
    marginTop:12,   
  },
  BdyborBtRTOP3: {
    borderBottom: "solid 0.1px",
    // borderRight: "solid 0.1px",
    borderTop: "solid 0.1px",
    marginTop:12,  
    // borderLeft: "solid 0.1px",    
  },
  BdymtTabl: {
    border: "solid 0.1px",
    borderRadius: "6px",
    padding: 2,
    margin: 10,
  },

  Fotrroot: {
    background: "red",
  },
}));

/*
const useInvHeadrAF = makeStyles((theme) => ({
  Hedroot: {
    // flexGrow: 1,
    // display:'flex',
    marginTop:'0.8rem',
    border: 'solid 1px',
    borderRadius: '20px',
    width: '96%',
    marginLeft: '2%',
    padding: '15px',
    // zIndex: 1,
  },
  HedtotSub:{
    // padding:2,
    // marginLeft:8,
    // marginRight:8,
    // marginTop:-2,
    border: 'solid 0.1px',
    borderRadius: '6px',
    padding: 2,
    margin:10,
    marginBottom:0,
  },
  HedtxtLeft:{
    bottom: '1px',
    display: 'flex',
    border: 'solid 1px',
    borderRadius:5,
  },
}));
*/

/*
// const useInvBodyAF = makeStyles((theme) => ({
//   Bdyroot:{
//     flexGrow: 1,
//   },
//   BdyundrHeaderTF: {
//     marginTop: '1rem',
//     // zIndex: 1,
//   },
//   BdyundrHeader: {
//     marginTop: '0.6rem',
//     // border: 'solid 1px',
//     marginBottom: '2rem',
//     // zIndex: 1,
//   },
//   BdytitleHed:{
//     // color:'#8D201C',
//     backgroundColor:'#f5ebdb ',
//     textAlign:'center',
//     border: 'solid 1px',
//     borderRadius: '6px',
//     padding: 6,
//   },
//   BdytotTitle:{
//     paddingLeft:12,
//     paddingRight:12,
//     border: 'solid 0.1px',
//     borderRadius: '6px',
//     padding: 2,
//     margin:10,
//     marginBottom:0,
//   },
//   BdytotSub:{
//     padding:2,
//     marginLeft:8,
//     marginRight:8,
//     marginTop:-2,
//     // border: 'solid 0.1px',
//     // borderRadius: '6px',
//     // padding: 2,
//     // margin:10,
//     // marginBottom:0,
//   },
//   BdytotLf:{
//     paddingLeft:10,
//     borderLeft: 'solid 0.1px',
//     borderBottom: 'solid 0.1px',
//   },
//   BdytotRit:{
//     borderBottom: 'solid 0.1px',
//     borderRight: 'solid 0.1px',
//     textAlign:'center',
//   },
//   BdytotCntr:{
//     paddingRight:10,
//     borderLeft: 'solid 0.1px',
//     borderBottom: 'solid 0.1px',
//     borderRight: 'solid 0.1px',
//     textAlign:'right',
//   },
//   BdyborBtL: {
//     borderBottom: 'solid 0.1px',
//     borderLeft: 'solid 0.1px',
//    },
//    BdyborBtR: { 
//     borderBottom: 'solid 0.1px',
//     borderRight: 'solid 0.1px',
//   },
//   BdymtTabl:{
//     border: 'solid 0.1px',
//     borderRadius: '6px',
//     padding: 2,
//     margin:10,
//   },
// }));
*/

/*
const useInvFootrAF = makeStyles((theme) => ({
  Fotrroot:{
   background:'red'
  },  
}));
*/
//================= E AF

//================= B Casher

const useInvCa = makeStyles((theme) => ({
  /* S Head */
  Hedroot: {
    marginTop: "1mm",
    border: "solid 1px",
    borderRadius: "7px",
    fontWeight: "500",
    paddingLeft: 10,
    paddingRight: 10,
  },
  Hedrest: {
    marginTop: "2mm",
    borderRadius: "7px",
    fontWeight: "500",
    paddingLeft: 10,
    paddingRight: 10,
  },
  contnt: {
    fontSize: "0.6rem",
    fontFamily: "Amiri-Regular",
    //  fontWeight:'700',
    fontWeight: "bold",
  },
  colr: {
    color: "#840907",
  },
  contntSub: {
    fontSize: "0.6rem",
    //  fontWeight:'700',
    fontFamily: "Amiri-Regular",
    fontWeight: "bold",
  },
  Hedlogo: {
    height: 70,
    width: 90,
  },
  /* E Head */

  /* S Bdy */
  Bdyroot: {
    flexGrow: 1,
    fontSize: "0.6rem",
    fontWeight: "700",
    fontFamily: "Amiri-Regular",
  },
  Bdymt: {
    marginTop: "1rem",
  },
  BdyundrHeaderTF: {
    // marginTop: '1.5mm',
    margin: "1.2mm 2mm 1.2mm 2mm",
    // margin: "0.5rem",
    
    // zIndex: 1,
  },
  BdyundrHeader: {
    marginTop: "1mm",
    // border: 'solid 1px',
    marginBottom: "1rem",
    // zIndex: 1,
  },
  BdytitleHed: {
    // color:'#8D201C',
    backgroundColor: "#f5ebdb",
    textAlign: "center",
    border: "solid 1px",
    borderRadius: "5px",
    padding: "3px 1px 3px 1px",
    // margin: '3px 1px 3px 1px',
  },
  BdytitleHedDw: {
    marginTop: "1mm",
  },
  BdytotTitle: {
    paddingLeft: 12,
    paddingRight: 12,
    fontSize: "0.6rem",
    fontWeight: "700",
    border: "solid 0.1px",
    borderRadius: "6px",
    padding: 2,
    marginTop: 10,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
  },
  BdytotSub: {
    // padding:2,
    fontSize: "0.5rem",
    fontWeight: "700",
    marginLeft: 0,
    marginRight: 0,
    marginTop: -2,
  },
  BdytotResSub: {
    // padding:2,
    fontSize: "0.7rem",
    fontWeight: "700",
    marginLeft: 0,
    marginRight: 0,
    marginTop: -2,
  },
  BdytotCntr: {
    // padding:2,
    // padding:'2px 1px 0px 1px',
    borderLeft: "solid 0.1px",
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    textAlign: "center",
    alignItems: "center",
    display: "grid",
  },
  BdytotCntrVal: {
    padding: 1.5,
    borderLeft: "solid 0.1px",
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    textAlign: "center",
    fontSize: "1rem",
    display: "inline-grid",
    alignItems: "center",
    display: "grid",
  },
  Bdycntr: {
    textAlign: "center",
    border: "solid 0.1px",
    borderRadius: 5,
    // fontSize:'0.6rem',
    // fontWeight:'700'
  },
  BdyborBtL: {
    borderBottom: "solid 0.1px",
    borderLeft: "solid 0.1px",
  },
  BdyborBtR: {
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
  },
  BdymtTabl: {
    border: "solid 0.1px",
    borderRadius: "6px",
    padding: 2,
    margin: 10,
  },
  Bdytabl: {
    backgroundColor: "#f5ebdb",
    textAlign: "center",
    fontSize: "0.5rem",
    fontWeight: "700",
    marginTop: "0.6rem",
    border: "solid 0.1px",
    borderRadius: "6px",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  Bdyrows: {
    // backgroundColor:'#f5ebdb',
    textAlign: "center",
    fontSize: "5rem",
    // marginTop: '0.6rem',
    // border: 'solid 0.1px',
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    borderLeft: "solid 0.1px",
    borderRadius: "6px",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  Bdyrow: {
    fontSize: "1rem",
  },
  BdyrowRes: { fontSize: "0.7rem" },
  BdyrowSy: { marginBottom: "0.5rem", fontWeight: "bold", fontSize: "0.8rem" },
  BdyrowSyRes: {
    marginBottom: "0.5rem",
    fontSize: "0.7rem",
  },

  /* B karji */
  BdytablSnd: {
    color: "#000",
    backgroundColor: "#c0c0c080",
    textAlign: "-webkit-center",
    fontSize: "0.4rem",
    fontWeight: "700",
    marginTop: "0.6rem",
    border: "solid 0.1px",
    borderRadius: "6px",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
    whiteSpace: "nowrap",
  },
  bG: { marginLeft: 2, marginRight: 2 },
  // Brow:{ marginBottom:'0.5rem', fontWeight:'bold',fontSize:'0.5rem' },
  // rowkh:{ fontSize:'0.5rem' },
  Browskh: {
    color: "#000",
    textAlign: "-webkit-center",
    fontSize: "0.5rem",
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    borderLeft: "solid 0.1px",
    borderRadius: "6px",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
    whiteSpace: "nowrap",
    direction: "rtl",
  },
  /* E karji */

  /* E Bdy */

  /* S Footr */
  Fotrroot: {
    background: "red",
  },
  /* E Footr */
}));

/*
const useInvBodyCa = makeStyles((theme) => ({
  Bdyroot:{
    flexGrow: 1,
  },
  Bdymt:{
    marginTop: '1rem',
  },
  BdyundrHeaderTF: {
    marginTop: '1rem',
    // zIndex: 1,
  },
  BdyundrHeader: {
    marginTop: '2.6rem',
    border: 'solid 1px',
    marginBottom: '1rem',
    // zIndex: 1,
  },
  BdytitleHed:{
    // color:'#8D201C',
    backgroundColor:'#f5ebdb',
    textAlign:'center',
    border: 'solid 1px',
    borderRadius: '6px',
    padding: 6,
  },
  BdytotTitle:{
    paddingLeft:12,
    paddingRight:12,
    border: 'solid 0.1px',
    borderRadius: '6px',
    padding: 2,
    marginTop:10,
    marginLeft:0,
    marginRight:0,
    marginBottom:0,
  },
  BdytotSub:{
    // padding:2,
    
    marginLeft:0,
    marginRight:0,
    marginTop:-2,
  },
  BdytotCntr:{
    // padding:2,
    // padding:'2px 1px 0px 1px',
    borderLeft: 'solid 0.1px',
    borderBottom: 'solid 0.1px',
    borderRight: 'solid 0.1px',
    textAlign:'center',
    alignItems: 'center',
    display: 'grid',

  },
  BdytotCntrVal:{
    padding:1.5,
    borderLeft: 'solid 0.1px',
    borderBottom: 'solid 0.1px',
    borderRight: 'solid 0.1px',
    textAlign:'center',
    fontSize: '1rem',
    display: 'inline-grid',
    alignItems: 'center',
    display: 'grid',
  },
  Bdycntr: {
      textAlign:'center',
      border: 'solid 0.1px',
      borderRadius: 5,
    },
  borBtL: {
    borderBottom: 'solid 0.1px',
    borderLeft: 'solid 0.1px',
   },
   BdyborBtR: { 
    borderBottom: 'solid 0.1px',
    borderRight: 'solid 0.1px',
  },
  BdymtTabl:{
    border: 'solid 0.1px',
    borderRadius: '6px',
    padding: 2,
    margin:10,
  },
  Bdytabl:{
    backgroundColor:'#f5ebdb',
    textAlign:'center',
    fontSize:'0.7rem',
    marginTop: '0.6rem',
    border: 'solid 0.1px',
    borderRadius: '6px',
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  Bdyrows:{
    // backgroundColor:'#f5ebdb',
    textAlign:'center',
    fontSize:'0.7rem',
    // marginTop: '0.6rem',
    // border: 'solid 0.1px',
    borderBottom: 'solid 0.1px',
    borderRight: 'solid 0.1px',
    borderLeft: 'solid 0.1px',
    borderRadius: '6px',
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  Bdyrow:{
  fontSize:'1rem'
},
BdyrowSy:{ marginBottom:'0.5rem', fontWeight:'bold',fontSize:'0.8rem' },
}));
*/
/*
const useInvFootrCa = makeStyles((theme) => ({
  root:{
   background:'red'
  },  
}));
*/
//================= B Casher
//====================================================== E Inv

//====================================================== B MultiSarf
//================= B AF
const useMultiSHeadrAF = makeStyles((theme) => ({}));

const useMultiSBodyAF = makeStyles((theme) => ({}));

const useMultiSFootrAF = makeStyles((theme) => ({
  root: {
    background: "red",
  },
}));
//================= E AF

//================= B Casher
//================= E Casher
//====================================================== E MultiSarf

//====================================================== B SndS

/* ------------- */

//====================================================== E SndS

//====================================================== end menu btn

/* ====================================================== B Reports */

//================= B AF
const useReviws = makeStyles((theme) => ({
  /* S Head */
  Hedlogo: {
    height: 80,
    width: 100,
  },
  /* E Head */

  /* S Bdy */
  Bdyroot: {
    flexGrow: 1,
  },
  Bdymt: {
    marginTop: "1rem",
  },
  BdyundrHeaderTF: {
    marginTop: "1rem",
    // zIndex: 1,
  },
  BdyundrHeader: {
    marginTop: "0.6rem",
    // border: 'solid 1px',
    marginBottom: "0.6rem",
    // zIndex: 1,
  },

  BdytitleHed: {
    // color:'#8D201C',
    backgroundColor: "#f5ebdb",
    textAlign: "center",
    border: "solid 1px",
    borderRadius: "6px",
    padding: 5,
  },
  BdytitleHedMmm: {
    // color:'#8D201C',
    backgroundColor: "#f5ebdb",
    textAlign: "center",
    border: "solid 1px",
    borderRadius: "6px",
    padding: 5,
  },
   BdytitleHedMus: {
    // color:'#8D201C',
    backgroundColor: "#f5ebdb",
    textAlign: "center",
    border: "solid 1px",
    borderRadius: "6px",
    padding: 1,
    fontSize:"1rem",
  },
  BdytotTitle: {
    paddingLeft: 12,
    paddingRight: 12,
    border: "solid 0.1px",
    borderRadius: "6px",
    padding: 2,
    marginTop: 10,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
  },
  BdytotSub: {
    // padding:2,
    // marginLeft:0,
    // marginRight:0,
    marginTop: "0.5rem",
    textAlign: "center",
    justifyContent: "center",
    direction: "rtl",
  },
  BtotT: {
    background: "#a9a9a9",
    margin: "0px 0px 3px 2px",
  },
  BtotV: {
    margin: "0px 5px 3px 0px",
    border: "dotted 0.1px",
  },
  Bdytot: {
    padding: "0.4rem 0rem",
  },
  BdytotCntr: {
    // padding:2,
    // padding:'2px 1px 0px 1px',
    borderLeft: "solid 0.1px",
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    textAlign: "center",
    alignItems: "center",
    display: "grid",
  },
  BdytotCntrVal: {
    padding: 1.5,
    borderLeft: "solid 0.1px",
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    textAlign: "center",
    fontSize: "1rem",
    display: "inline-grid",
    alignItems: "center",
    display: "grid",
  },
  Bdycntr: {
    textAlign: "center",
    border: "solid 0.1px",
    borderRadius: 5,
  },
  BdyborBtL: {
    borderBottom: "solid 0.1px",
    borderLeft: "solid 0.1px",
  },
  BdyborBtR: {
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
  },
  BdymtTabl: {
    border: "solid 0.1px",
    borderRadius: "6px",

    // padding: 2,
    marginTop: "0.5rem",
  },
  Bdytabl: {
    backgroundColor: "#f5ebdb",
    textAlign: "center",
    fontSize: "0.7rem",
    marginTop: "0.6rem",
    border: "solid 0.1px",
    borderRadius: "6px",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  Bdyrows: {
    // backgroundColor:'#f5ebdb',
    textAlign: "center",
    fontSize: "0.7rem",
    // marginTop: '0.6rem',
    // border: 'solid 0.1px',
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    borderLeft: "solid 0.1px",
    borderRadius: "6px",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  BdyrowsEvDy: {
    // backgroundColor:'#f5ebdb',
    textAlign: "center",
    fontSize: "0.7rem",
    // marginTop: '0.6rem',
    // border: 'solid 0.1px',
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    borderLeft: "solid 0.1px",
    borderRadius: "6px",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  Bdyrow: {
    fontSize: "1rem",
  },
  BdyrowSy: { marginBottom: "0.5rem", fontWeight: "bold", fontSize: "0.8rem" },
  /* E Bdy */

  /* S Footr */
  Fotrroot: {
    background: "red",
  },
  /* E Footr */
}));

const useRptMain = makeStyles((theme) => ({
  /* S main */
  mincntr: { textAlign: "center" },
  minrit: { textAlign: "right" },
  minborR: { borderRight: "solid 0.1px" },
  minborL: { borderLeft: "solid 0.1px" },
  minborB: { borderBottom: "solid 0.1px" },
  minclr: { color: "#7f0101", boxShadow: "none" },
  minroot: {
    // flexGrow: 1,
    // display:'flex',
    marginTop: 10,
    border: "solid 1px",
    borderRadius: "10px",
    padding: "5px",
    // zIndex: 1,
  },
  mintotSub: {
    // padding:2,
    // marginLeft:8,
    // marginRight:8,
    // marginTop:-2,
    border: "solid 0.1px",
    borderRadius: "6px",
    padding: 2,
    margin: 10,
    marginBottom: 0,
  },
  mintxtLeft: {
    bottom: "1px",
    display: "flex",
    border: "solid 1px",
    borderRadius: 5,
  },
  /* E main */
}));

const useRptAF = makeStyles((theme) => ({
  /* E Head */
  root: {
    flexGrow: 1,
    textAlign: "center",
    fontFamily: "Amiri-Regular",
    // alignSelf: 'center',
    // boxShadow: 'inset 0px 0px 0px 2px orange',
    height: "7cm",
  },
  rootMu: {
    flexGrow: 1,
    textAlign: "center",
    fontFamily: "Amiri-Regular",
    // alignSelf: 'center',
    marginTop:10,
    // boxShadow: 'inset 0px 0px 0px 2px orange',
    height: "8cm",
  },
  root1: {
    flexGrow: 1,
    textAlign: "center",
    fontFamily: "Amiri-Regular",
    // alignSelf: 'center',
    // boxShadow: 'inset 0px 0px 0px 2px orange',
    height: "5cm",
  },
  rootsub: {
    alignSelf: "center",
  },
  logo: {
    height: 80,
    width: 100,
  },
  title: {
    border: "solid 2px",
    borderRadius: "5px",
    padding: "0.5rem",
    fontFamily: "Amiri-Regular",
  },
  titleM: {
    margin: "1rem 0rem 0rem 0rem",
    fontFamily: "Amiri-Regular",
  },
  titleS: {
    direction: "rtl",
    margin: "0.2rem 0rem 0.3rem 0rem",
  },
  titleMu: {
    direction: "rtl",
    margin: "0.1rem 0rem 0.2rem 0rem",
  },
  titLin1: {
    margin: "0% 15% 0% 15%",
  },
  titLin2: {
    margin: "1% 5% 0% 5%",
  },
  titLin3: {
    margin: "1% 5% 0% 5%",
  },
  tlR: {
    textAlign: "right",
  },
  body: {
    // margin: '0% 15% 0% 15%',
    // border: 'solid 2px',
    borderRadius: "2px",
    padding: "0.5rem",
    direction: "rtl",
  },
  tblHd: {
    // tblHed
    border: "solid 2px",
  },
  /* E Head */

  /* S Body */
  rootBdy: {
    // boxShadow: 'inset 0px 0px 0px 2px green',
    fontFamily: "Amiri-Regular",
    // height: '16cm',
  },
  rootBdyup: {
    direction: "rtl",
    padding: "0.5rem 0rem 0rem 0rem",
    // border:'solid 0.1px'
  },
  rootBdydown: {
    direction: "rtl",
    marginTop: "0.5rem",
    // padding:'0.8rem 0rem 1rem 0rem',
    // border:'solid 0.1px'
  },
  rootinfAcc: {
    direction: "ltr",
  },
  downinfAcc: {
    paddingTop: "0.5rem",
  },
  infAcc: {
    textAlign: "center",
  },
  chipT: {
    textAlign: "center",
    border: "solid 1px",
    padding: "0.3rem 0.1rem 0.3rem 0.1rem",
    margin: "2px 1px",
    fontSize: 12,
    
    background: "#a9a9a9",
  },
  chipTMU: {
    textAlign: "center",
    
    padding: "0.3rem 0.1rem 0.3rem 0.1rem",
    margin: "2px 1px",
    fontSize: 12,
  
  },
  chipV: {
    textAlign: "center",
    border: "solid 1px",
    padding: "0.3rem 0.1rem 0.3rem 0.1rem",
    margin: "2px 1px",
    fontSize: 17,
  },
  chipVMU: {
    textAlign: "center",
    padding: "0.2rem 0.1rem 0.2rem 0.1rem",
    fontSize: 15,
  },
  chipTR: {
    borderRadius: "0px 5px 5px 0px",
  },
  chipTL: {
    borderRadius: "5px 0px 0px 0px",
  },
  chipVR: {
    borderRadius: "0px 0px 5px 0px",
  },
  chipVL: {
    borderRadius: "0px 0px 0px 5px",
  },
  rootchip: {
    //   // border:'solid 0.1px',
  },
  /* E Body */

  /* S Footr */
  rootFotr: {
    // boxShadow: 'inset 0px 0px 0px 4px red',
    marginTop: "1.6cm",
    fontFamily: "Amiri-Regular",
    // hei
  },
  rootFotr1: {
    // boxShadow: 'inset 0px 0px 0px 4px red',
    // marginTop: "0.5cm",
    fontFamily: "Amiri-Regular",
    // height: '5.4cm',
  },
  line: {
    padding: "0.1rem",
    margin: "0rem 0rem 0rem 0rem",
    background: "#000",
  },
  titleF: {
    margin: "1rem 0rem 0rem 0rem",
    border: "solid 2px",
    padding: "0.6rem 0rem",
  },
  subF: {
    margin: "0rem 0rem 0rem 0rem",
    borderLeft: "solid 1px",
    borderRight: "solid 2px",
    borderBottom: "solid 2px",
    padding: "0.3rem 0rem",
  },
  infoF: {
    borderLeft: "solid 1px",
  },
  /* E Footr */
}));
// const useRptHedAF = makeStyles((theme) => ({ }));
// const useRptBodyAF = makeStyles((theme) => ({ }));
// const useRptFootrAF = makeStyles((theme) => ({ }));
//================= E AF

//================= B Casher
const useRptCa = makeStyles((theme) => ({
  /* S Head */
  content: {
    color: "red",
    background: "red",
  },
  Hedlogo: {
    height: 80,
    width: 100,
  },
  /* E Head */

  /* S Bdy */
  Bdyroot: {
    flexGrow: 1,
  },
  Bdymt: {
    marginTop: "1rem",
  },
  BdyundrHeaderTF: {
    marginTop: "1rem",
    // zIndex: 1,
  },
  BdyundrHeaderTB: {
    marginTop: "1rem",

    marginBottom: "1rem",
    // zIndex: 1,
  },
  BdyundrHeader: {
    marginTop: "0.6rem",
    // border: 'solid 1px',
    marginBottom: "1rem",
    // zIndex: 1,
  },
  BdytitleHed: {
    // color:'#8D201C',
    backgroundColor: "#f5ebdb",
    textAlign: "center",
    border: "solid 1px",
    borderRadius: "6px",
    padding: 6,
  },
  BdytotTitle: {
    paddingLeft: 12,
    paddingRight: 12,
    border: "solid 0.1px",
    borderRadius: "6px",
    padding: 2,
    marginTop: 10,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
  },
  BdytotSub: {
    // padding:2,
    // marginLeft:0,
    // marginRight:0,
    marginTop: "0.5rem",
    textAlign: "center",
    justifyContent: "center",
    direction: "rtl",
  },
  BtotT: {
    background: "#a9a9a9",
    margin: "0px 0px 3px 2px",
  },
  BtotV: {
    margin: "0px 5px 3px 0px",
    border: "dotted 0.1px",
  },
  Bdytot: {
    padding: "0.4rem 0rem",
  },
  BdytotCntr: {
    // padding:2,
    // padding:'2px 1px 0px 1px',
    borderLeft: "solid 0.1px",
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    textAlign: "center",
    alignItems: "center",
    display: "grid",
  },
  BdytotCntrVal: {
    padding: 1.5,
    borderLeft: "solid 0.1px",
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    textAlign: "center",
    fontSize: "1rem",
    display: "inline-grid",
    alignItems: "center",
    display: "grid",
  },
  Bdycntr: {
    textAlign: "center",
    border: "solid 0.1px",
    borderRadius: 5,
  },
  BdyborBtL: {
    borderBottom: "solid 0.1px",
    borderLeft: "solid 0.1px",
  },
  BdyborBtR: {
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
  },
  BdymtTabl: {
    border: "solid 0.1px",
    borderRadius: "6px",

    // padding: 2,
    marginTop: "0.5rem",
  },
  Bdytabl: {
    backgroundColor: "#f5ebdb",
    textAlign: "center",
    fontSize: "0.7rem",
    marginTop: "0.6rem",
    border: "solid 0.1px",
    borderRadius: "6px",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  Bdyrows: {
    // backgroundColor:'#f5ebdb',
    textAlign: "center",
    fontSize: "0.7rem",
    // marginTop: '0.6rem',
    // border: 'solid 0.1px',
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    borderLeft: "solid 0.1px",
    borderRadius: "6px",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  BdyrowsEvDy: {
    // backgroundColor:'#f5ebdb',
    textAlign: "center",
    fontSize: "0.7rem",
    // marginTop: '0.6rem',
    // border: 'solid 0.1px',
    borderBottom: "solid 0.1px",
    borderRight: "solid 0.1px",
    borderLeft: "solid 0.1px",
    borderRadius: "6px",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  Bdyrow: {
    fontSize: "1rem",
  },
  BdyrowSy: { marginBottom: "0.5rem", fontWeight: "bold", fontSize: "0.8rem" },
  /* E Bdy */

  /* S Footr */
  Fotrroot: {
    background: "red",
  },
  clrSk: { background: "#c0ffff", border: "dotted 0.1px" },
  clrPi: { background: "#ffe0c0", border: "dotted 0.1px" },
  clrGr: { background: "#c0ffc0", border: "dotted 0.1px" },
  clrYe: { background: "#ffffc0", border: "dotted 0.1px" },

  /* E Footr */
}));
// const useRptHedCasher = makeStyles((theme) => ({ }));
// const useRptBodyCasher = makeStyles((theme) => ({ }));
// const useRptFootrCasher = makeStyles((theme) => ({ }));
//================= E Casher

/* ====================================================== E Reports */

const useInvMain = makeStyles((theme) => ({
  /* S main */
  HedrootSKh: { border: "solid 1px #fff" },

  mincntr: { textAlign: "center", fontFamily: "Amiri-Regular" },
  minrit: { textAlign: "right", fontFamily: "Amiri-Regular" },
  minleft: { textAlign: "reft", fontFamily: "Amiri-Regular" },
  minborR: { borderRight: "solid 0.1px" },
  minborL: { borderLeft: "solid 0.1px" },
  minborB: { borderBottom: "solid 0.1px" },
  minclr: {
    color: "#7f0101",
    fontWeight: "bold",
    fontFamily: "Amiri-Regular",
    boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%)",
  },
  minclr22: {
    color: "#7f0101",
    fontWeight: "bold",
    fontSize: "0.7rem",
    fontFamily: "Amiri-Regular",
    boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%)",
  },
  minroot: {
    // flexGrow: 1,
    // display:'flex',
    marginTop: 10,
    border: "solid 1px",
    borderRadius: "10px",
    padding: "5px",

    // zIndex: 1,
  },
  mintotSub: {
    // padding:2,
    // marginLeft:8,
    // marginRight:8,
    // marginTop:-2,
    border: "solid 0.1px",
    borderRadius: "6px",
    padding: 2,
    margin: 10,
    marginBottom: 0,
  },
  mintxtLeft: {
    bottom: "1px",
    display: "flex",
    border: "solid 1px",
    borderRadius: 5,
  },
  /* E main */
}));

const useStylesSpDial = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // position: 'relative',
  },
  speedDial: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  ico: {
    background: "#fff",
    width: "100%",
    height: "100%",
    borderRadius: 50,
    padding: 7,
    // color:insh.colors.primary,
    color: insh.colors.secndry,
  },
  clPri: { color: insh.colors.primary },
  clSec: { color: insh.colors.secndry },
  clsal: { color: insh.colors.sal },
  clpur: { color: insh.colors.pur },
  clsaf: { color: insh.colors.saf },
  clacc: { color: insh.colors.acc },
  clcan: { color: insh.colors.cancl },
  // rootsd: {
  //   // flexGrow: 1,
  //   positionC: 'relative',
  // },
  // speedDialsdC: {
  //   position: 'fixed',
  //   bottom: theme.spacing(2),
  //   right: theme.spacing(2),
  //   // opacity: 0,
  //   // background:'green',
  //   // color:'orange'
  // },
  // SpeedDialIconsdC:{
  //   // background:'orange',
  //   // color:'orange'
  // },
}));

export {
  useInvAF,
  //  useInvHeadrAF,useInvBodyAF,useInvFootrAF,
  //  useInvHeadrCa,useInvBodyCa,useInvFootrCa,
  useInvCa,
  useInvMain,
  useMultiSHeadrAF,
  useMultiSBodyAF,
  useMultiSFootrAF,
  useRptAF,
  // useRptHedAF,useRptBodyAF,useRptFootrAF,
  useRptCa,
  useRptMain,
  useReviws,
  // useRptHedCasher,useRptBodyCasher,useRptFootrCasher,
  useStylesSpDial,
};

/* 4 screan  */

// [theme.breakpoints.up('lg')]: {
//   background:'orange',
//   },
// [theme.breakpoints.up('md')]: {
//   background:'red',
// },
// [theme.breakpoints.down('sm')]: {
//   background:'green',
// },
// [theme.breakpoints.down('xs')]: {
//   background:'brown',
// },
